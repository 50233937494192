import React, { useState, useEffect } from "react";
import "../ShareListing/ShareListing.scss";
import EmailIcon from "../../images/email.png"
import FacebookIcon from "../../images/Facebook_black.png"
import InstagramIcon from "../../images/Instagram_black.png"
import LinkedinIcon from "../../images/LinkedIN_black.png"
import WhatsappIcon from "../../images/whatsapp.png"
import MessageIcon from "../../images/message.png"


const ShareList = () =>{

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const [Shareicons,setShareicons] = useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if(Shareicons === true) {
            setShareicons(false);
        }
        }
    
        const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' +shareurl,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });    
        }

    return(
        <div className="social-share-section">
           <div>
             <a href={`mailto:?body=${shareurl}`}><img src={EmailIcon} alt="email-icon"/></a>
           </div>

           <div>
              <a href="/contact-us/"><img src={MessageIcon} alt="message-icon"/></a>
            </div>

            <div>
              <a href={`https://api.whatsapp.com/send?text=${shareurl}`}><img src={WhatsappIcon} alt="whatsapp-icon" onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} /></a>
            </div>

            
            <div>
              <a href={`https://www.instagram.com/?url=${shareurl}`}><img src={InstagramIcon} alt="instagram-icon" /></a>
            </div>

           <div>
             <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareurl}`}><img src={FacebookIcon} alt="facebook-icon" onClick={()=>trackerShare('FacebookShareButton')} url={shareurl}/></a>
            </div>


            <div>
              <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareurl}/&summary=`}><img src={LinkedinIcon} alt="linkedin-icon"  onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl}/></a>
            </div>
          
        </div>
    )
}
export default ShareList