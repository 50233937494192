import React from 'react';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import BrandLogo from "../images/icons/logo.svg";
import PropertyBreadcrumb from "../components/Breadcrumb/PropertyBreadcrumb";
import PropertyDetailsBanner from "../components/PropertyDetailsBanner/PropertyDetailsBanner";
import PropertyDetailsDescription from "../components/PropertyDetailsDescription/PropertyDetailsDescription";
import PropertyDetailMap from "../components/map/detail-map";
import PropertyAreaGuide from "../components/PropertyAreaGuide/PropertyAreaGuide";
import PropertyDetailsViewing from "../components/PropertyDetailsViewing/PropertyDetailsViewing";
import PropertyDetailCalculator from '../components/PropertyDetailCalculator/PropertyDetailCalculator';
import SimilarProperties from "../components/SimilarProperties/SimilarProperties";
import SEO from "../components/Seo/seo";
import PropertyDetailsFloatingButton from '../components/PropertyDetailsFloatingButton/PropertyDetailsFloatingButton';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import { navigate } from "@reach/router";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!) {
    properties(where:{id: $id}) {
      id
      search_type
      status
      publish
      building
      department
      address
      images
      imagetransforms
      virtual_tour
      floorplan
      display_address
      price
      epc
      brochure
      price_qualifier
      bedroom
      bathroom
      reception
      floor_area
      floorarea_type
      accomadation_summary
      long_description
      epc
      extra
      latitude
      office_crm_id
      longitude
    }
  }
`;

const PropertyDetails = () => {
    // 
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    };
    // 
    //
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });
    if(data && data.properties && data.properties[0].publish == false){
      navigate('/404');
    };
    // Loading logo
    if (loading) return <LoadingScreen />;
    // Loading logo

  return (
    <>
      <Header />

      {
        data && data.properties && data.properties.length && data.properties[0].publish > 0 ?
        (
          data.properties.map((data, i) => {
            var searchaction = data.search_type === "sales"?"for sale":"to rent"            
            const bedroomText = data.bedroom > 1 ? " bedrooms" : " bedroom"
            var metatile = ""+capitalize(data.building && data.building.length > 0 && data.building.replace(/-/g, ' ').replace('apartments', 'Apartment'))+" "
            +searchaction+" with "+data.bedroom+bedroomText+" in "+data.display_address+" at £"+data.price.toLocaleString();
            var metadesc = "Know the details of "+capitalize(data.building && data.building.length > 0 && data.building.replace(/-/g, ' ').replace('apartments', 'Apartment'))+" "+searchaction+" with "+data.bedroom+bedroomText+" in "+data.display_address+" at £"+data.price.toLocaleString()+". Book a viewing to get assistance in finding the right "+capitalize(data.building && data.building.length > 0 && data.building.replace(/-/g, ' '))+" for you.";
            
            return (
              <>
              <section className="property-details-wrapper">
                <SEO image={data.images && data.images[0].url} title={metatile} description={metadesc} />
                <PropertyBreadcrumb propertyData={data} />
                <PropertyDetailsBanner propertyData={data} />
                <PropertyDetailsDescription propertyData={data} />
                {data?.latitude && data?.longitude && (
                  <PropertyDetailMap
                    Latitude={data?.latitude}
                    Longitude={data?.longitude}
                  />
                )}
                <PropertyAreaGuide postcode={data && data?.address && data?.address?.postcode && data?.address?.postcode.split(" ")[0]} />
                {data.search_type === "sales"?
                  <PropertyDetailCalculator propertyValue={data.price}/>
                :""}
                <PropertyDetailsViewing propertyData={data} />
                <SimilarProperties propertyData={data} />
              </section>
            <Footer popularsearch="propertydetails" searchaction={searchaction} area={data?.address?.area} postcode={data && data?.address && data?.address?.postcode && data?.address?.postcode.split(" ")[0]}/>
                <PropertyDetailsFloatingButton propertyData={data} />
            </>
            )
          })
        )
        :
        ''
      }

    </>
  )
}

export default PropertyDetails